import React, { useEffect, useMemo } from "react";
import { useSession, useUser, useDescope } from "@descope/react-sdk";
import Header from "../component/header/Header";
import { Navigate, useLocation } from "react-router-dom";
import { getRolesForTenant } from "../utils/getRolesForTenant";
import { useAppDispatch } from "../redux/store";
import { GetProviderDetails } from "../redux/features/providerSlice";
import ErrorPage from "../pages/error/ErrorPage";

const PrivateRoute: React.FC<{ children: any }> = ({ children }) => {
  const { isAuthenticated } = useSession();
  const { user } = useUser();
  // const { logout } = useDescope();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const currentTenantId = user?.customAttributes?.currentTenantId;
  const currentRoles = useMemo(() => {
    return getRolesForTenant(user, currentTenantId);
  }, [user, currentTenantId]);

  useEffect(
    () => localStorage.setItem("path", location.pathname),
    [location.pathname]
  );

  useEffect(() => {
    if (isAuthenticated && currentTenantId) {
      dispatch(GetProviderDetails());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, currentTenantId]);

  if (user) {
    //find tenant that has roles key as Provider
    // const providerTenant = user?.userTenants?.filter((tenant) =>
    //   tenant.roleNames?.includes("Provider")
    // );
    // if (!providerTenant || providerTenant?.length === 0) {
    if (
      !(
        currentRoles.includes("Owner") ||
        currentRoles.includes("Employee") ||
        currentRoles.includes("Admin")
      )
    ) {
      return (
        // <div>
        //   <span>Only Employee has permission to use employee portal</span>
        //   <button onClick={() => logout()}>Logout</button>
        // </div>
        <ErrorPage/>
      );
    }
  }

  return isAuthenticated ? (
    <div>
      <Header />
      {children}
    </div>
  ) : (
    <Navigate to={"/login"} />
  );
};
export default PrivateRoute;
