import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import providerService from "../../service/provider.service";
import { setResponseValue } from "./api-response";
import {
  DefaultProviderDetails,
  defaultProviderAvailability,
} from "../../constants/providerDetails";
import {
  Board,
  IEmployeePortalData,
  ListData,
} from "../../type/providerDetails";
import MessagesSlice from "../../service/message.service";
import { IAvailabilityDetails, ISelectedDay } from "../../type/availability";
import { formattedDate } from "../../constants/common";
import { IEmployeeScheduleSettings } from "../../type/schedule";

// export interface IproviderDetail {
//   providerProfile: Iprovider;
//   onBoardingStages: string[];
//   providerSchedule: IProvideBookingDetails;
//   documentfiles:any[];
//   providerAllList:ProviderForms[];
//   allMessageList:AllUserMessage[];
// }

interface IproviderSlice {
  providerProfile: IEmployeePortalData;
  onBoardingStages: string[];
  providerAllList: any[];
  allMessageList: any[];
  employeeScheduleSetting: IEmployeeScheduleSettings;
}

const initialState: IproviderSlice = {
  providerProfile: { ...DefaultProviderDetails },
  onBoardingStages: [],
  providerAllList: [],
  allMessageList: [],
  employeeScheduleSetting: {
    allowEmployeeToUpdateAvailablity: false,
    allowEmployeeToSeeAvailablity: false,
  },
};
const providerSlice = createSlice({
  name: "providerSlice",
  initialState,
  reducers: {
    setAvailabilityDetails: (
      state,
      action: PayloadAction<IAvailabilityDetails[]>
    ) => {
      let data: IAvailabilityDetails[] = [];
      if (action.payload.length > 0) {
        data = [...action.payload].sort(
          (a, b) =>
            (new Date(formattedDate(a.beginDate)) as any) -
            (new Date(formattedDate(b.beginDate)) as any)
        );
      }
      return {
        ...state,
        // availabilityDetails: data,
        providerProfile: {
          ...state.providerProfile,
          availabilityDetails: data,
        },
      };
    },
    setFromTime: (
      state,
      action: PayloadAction<{
        availabilityIndex: number;
        index: number;
        value: string;
      }>
    ) => {
      state.providerProfile.availabilityDetails[
        action.payload.availabilityIndex
      ].selectedDays[action.payload.index].startTime = action.payload.value;
      return state;
    },
    setToTime: (
      state,
      action: PayloadAction<{
        index: number;
        value: string;
        availabilityIndex: number;
      }>
    ) => {
      state.providerProfile.availabilityDetails[
        action.payload.availabilityIndex
      ].selectedDays[action.payload.index].endTime = action.payload.value;
      return state;
    },
    setProviderSelectedDays: (
      state,
      action: PayloadAction<{
        availabilityIndex: number;
        newAvailability: ISelectedDay[];
      }>
    ) => {
      state.providerProfile.availabilityDetails[
        action.payload.availabilityIndex
      ].selectedDays = action.payload.newAvailability;
      return state;
    },
    setBeginDate: (
      state,
      action: PayloadAction<{ availabilityIndex: number; beginDate: string }>
    ) => {
      state.providerProfile.availabilityDetails[
        action.payload.availabilityIndex
      ].beginDate = action.payload.beginDate;
      return state;
    },
    setEndDate: (
      state,
      action: PayloadAction<{ availabilityIndex: number; endDate: string }>
    ) => {
      state.providerProfile.availabilityDetails[
        action.payload.availabilityIndex
      ].endDate = action.payload.endDate;
      return state;
    },
    setDay: (
      state,
      action: PayloadAction<{
        availabilityIndex: number;
        index: number;
        value: string;
      }>
    ) => {
      state.providerProfile.availabilityDetails[
        action.payload.availabilityIndex
      ].selectedDays[action.payload.index].dayOfWeek = action.payload.value;
      return state;
    },
    addNewAvailability: (state, action) => {
      return {
        ...state,
        providerProfile: {
          ...state.providerProfile,
          availabilityDetails: [
            ...state.providerProfile.availabilityDetails,
            {
              id: "",
              entityType: "Provider",
              entityId: null, //MUST BE null, cannot be "",
              beginDate: action.payload.beginDate,
              endDate: action.payload.endDate,
              createdAt: "",
              updatedAt: "",
              selectedDays: [
                {
                  dayOfWeek: "",
                  startTime: "",
                  endTime: "",
                },
              ],
              heatMap: [],
              timeSlots: [],
              availabilitySelectedDays: [],
              availabilityName: "",
            },
          ],
        },
      };
    },
    removeAvailability: (state, action) => {
      const num =
        state.providerProfile.availabilityDetails.length > 0
          ? state.providerProfile.availabilityDetails.length - 1
          : 0;
      let newAvailability: IAvailabilityDetails[] = [];
      if (num > -1) {
        newAvailability = state.providerProfile.availabilityDetails.filter(
          (_, index: number) => num !== index
        );
      }
      state.providerProfile.availabilityDetails = newAvailability;
    },
    deleteAvailability: (
      state,
      action: PayloadAction<{ availabilityIndex: number; index: number }>
    ) => {
      const newAvailability = state.providerProfile.availabilityDetails[
        action.payload.availabilityIndex
      ].selectedDays.filter(
        (_: any, index: number) => action.payload.index !== index
      );
      state.providerProfile.availabilityDetails[
        action.payload.availabilityIndex
      ].selectedDays = newAvailability;
      return state;
    },
    setInitialState: (state, action: PayloadAction<null>) => {
      console.log("availability 1", state.providerProfile.availabilityDetails);
      return {
        ...state,
        providerProfile: {
          ...state.providerProfile,
          availabilityDetails: state.providerProfile.availabilityDetails.filter(
            (item) => item.id
          ),
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      GetProviderDetails.fulfilled,
      (state, action: PayloadAction<IEmployeePortalData>) => {
        if (action.payload !== undefined) {
          state.providerProfile = action.payload;

          //seems not necessary!!
          // state.providerProfile.providerFiles = action.payload?.providerFiles;
          // state.providerProfile.providerForms = action.payload?.providerForms;
          // state.providerProfile.availabilityDetails = action.payload?.availabilityDetails;

          //note: re-assign default availability
          if (action.payload?.availabilityDetails?.length === 0) {
            state.providerProfile.availabilityDetails = [
              defaultProviderAvailability,
            ];
          }
        }
      }
    );
    builder.addCase(
      GetAllBoardingStages.fulfilled,
      (state, action: PayloadAction<Board>) => {
        if (action.payload) {
          state.onBoardingStages = action.payload.stages
            .filter((stage) =>
              action.payload.permission.viewProvider.includes(stage.id)
            )
            .map((item) => item.stageName);
        }
      }
    );
    builder.addCase(GetProviderBookingDetails.fulfilled, (state, action) => {
      console.log(action.payload, "action.payload");

      if (action.payload !== undefined) {
        state.providerProfile.bookingData = action.payload;
      }
    });
    // builder.addCase(
    //   getProvidersByEmployee.fulfilled,
    //   (state, action) => {
    //     if (action.payload !== undefined) {
    //   state.providerAllList = action.payload
    //     }
    //   }
    // );
    builder.addCase(getProviderFilesById.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          providerProfile: {
            ...state.providerProfile,
            providerFiles: action.payload.data,
          },
        };
      }
    });
    builder.addCase(deleteProviderFilesById.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        state.providerProfile.providerFiles =
          state.providerProfile.providerFiles.filter(
            (item: any) => item.id !== action.payload
          );
      }
    });
    // builder.addCase(GetParentMessages.fulfilled, (state, action) => {
    //   if (action.payload !== undefined) {
    //     return {
    //       ...state,
    //       allMessageList: action.payload,
    //     };
    //   }
    // });
    builder.addCase(getEmployeeScheduleSettings.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          employeeScheduleSetting: action.payload,
        };
      }
    });
  },
});

export const GetProviderDetails = createAsyncThunk(
  `/providerDetails`,
  async (_, { getState, dispatch }) => {
    try {
      const { data, status } = await providerService.getProviderDetails();

      if (status) {
        return data.data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    }
  }
);

export const GetAllBoardingStages = createAsyncThunk(
  `/OnboardingStages`,
  async (_, { getState, dispatch }) => {
    try {
      const { data, status } =
        await providerService.getProviderOnBoardingStages();
      if (status) {
        return data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    }
  }
);

//get booking details
export const GetProviderBookingDetails = createAsyncThunk(
  `/providerBookingDetails`,
  async (_, { getState, dispatch }) => {
    try {
      const { data, status } =
        await providerService.getProviderBookingDetails();
      if (status) {
        return data.data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    }
  }
);

//no need to create here!
// export const getProvidersByEmployee = createAsyncThunk(
//   `/getProvidersByEmployee`,
//   async (_, { getState, dispatch }) => {
//     try {
//       const { data, status } = await providerService.getProvidersByEmployee();

//       if (status) {
//         dispatch(setResponseValue({ name: "success", value: true }));
//         dispatch(setResponseValue({ name: "message", value: data.message }));
//         return data.data;
//       }
//     } catch (e: any) {
//       dispatch(setResponseValue({ name: "error", value: true }));
//       dispatch(
//         setResponseValue({
//           name: "message",
//           value: e.message,
//         })
//       );
//     }
//   }
// );

// get Child Files
export const getProviderFilesById = createAsyncThunk(
  "providerListSlice/getProvideFilesById",
  async ({ providerId }: { providerId: string }, { getState, dispatch }) => {
    try {
      const data = await providerService.getProviderFilesById(providerId);

      if (data.status) {
        // dispatch(setClientFilesFromResponse(data.data));
        return data;
      }
    } catch (e: any) {
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);
//delete files
export const deleteProviderFilesById = createAsyncThunk(
  "providers/deleteProviderFile",
  async ({ fileId }: { fileId: string }, { dispatch }) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { status, message } = await providerService.deleteFileById(fileId);
      if (status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(
          setResponseValue({
            name: "message",
            value: message,
          })
        );
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
      return fileId;
    }
  }
);

export const updateProviderAvailabilityById = createAsyncThunk(
  "providers/updateAvailability",
  async (_, { getState, dispatch }) => {
    dispatch(setResponseValue({ name: "pending", value: true }));

    try {
      let { providerListSlice } = getState() as { providerListSlice: ListData };
      let { providerSlice } = getState() as {
        providerSlice: IproviderSlice;
      };
      dispatch(setResponseValue({ name: "pending", value: true }));
      var newAvailabilityArray: any[] = [];
      providerSlice.providerProfile.availabilityDetails.forEach((item) => {
        if (item.entityId === undefined || item.entityId === null) {
          const newObj: any = {
            entityId:
              providerListSlice?.singleProvider.employmentDetails.id ||
              providerSlice.providerProfile.employmentDetails.id,
            beginDate: item.beginDate,
            endDate: item.endDate,
            selectedDays: item.selectedDays,
            availabilitySelectedDay: item.availabilitySelectedDays,

            entityType: "Provider",
            heatMap: item.heatMap,
            id: item.id,
            timeSlots: item.timeSlots,
            createdAt: item.createdAt,
            updatedAt: item.updatedAt,
          };
          newAvailabilityArray.push(newObj);
        } else {
          newAvailabilityArray.push(item);
        }
      });
      const { status, message } =
        await providerService.updateAvailabilityDetails(newAvailabilityArray);
      if (status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        dispatch(GetProviderDetails());
      }
    } catch (e: any) {
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//delete single availability
export const deleteSingleAvailability = createAsyncThunk(
  "/deleteavailability/id",
  async ({ availableId }: { availableId: string }, { dispatch, getState }) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    let { providerSlice } = getState() as { providerSlice: IproviderSlice };
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { status, message } = await providerService.deleteAvailabilityById(
        availableId
      );
      if (status) {
        const newAvailability =
          providerSlice.providerProfile.availabilityDetails.filter(
            (item) => item.id !== availableId
          );
        dispatch(setAvailabilityDetails(newAvailability));

        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//fetches the messages
// export const GetParentMessages = createAsyncThunk(
//   `/parentMessage`,
//   async ({ id }: { id: string }, { getState, dispatch }) => {
//     try {
//       dispatch(setResponseValue({ name: "pending", value: true }));
//       const data = await MessagesSlice.getParentMessage(id);

//       return data.data;
//     } catch (e: any) {
//       //do something if needed
//     } finally {
//       dispatch(setResponseValue({ name: "pending", value: false }));
//     }
//   }
// );

export const getEmployeeScheduleSettings = createAsyncThunk(
  "/getParentPortalSchedule",
  async (_, { dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data } = await providerService.getEmployeeSetting();
      if (data) {
        return data;
      }
    } catch (e: any) {
      console.log(e);
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e?.response?.message?.data || "Error occured",
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const updateLastLogin = createAsyncThunk(
  "user/updateLastLogin",
  async (
    {
      email,
    }: {
      email: string;
    },
    { dispatch }
  ) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { status, message } = await providerService.updateLastLogIn(email);

      if (status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e?.response?.data?.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const {
  setAvailabilityDetails,
  setFromTime,
  setToTime,
  setProviderSelectedDays,
  setBeginDate,
  setEndDate,
  setDay,
  addNewAvailability,
  removeAvailability,
  deleteAvailability,
  setInitialState,
} = providerSlice.actions;

export default providerSlice;
