import { useDescope } from "@descope/react-sdk";
import ABAEngineLogo from "../../assets/logos/abaengine.png";
import Alert from "../../assets/images/images-svg/alert.svg";
import "./errorPage.scss"

const ErrorPage = () => {
  const { logout } = useDescope();
  return (
    <div className="errorPage">
      <div className="errorHeader">
        <img alt="logo" src={ABAEngineLogo} height={"50px"} />
      </div>

      <div className="errorbody">
        <div className="errorPage__container">
          <img src={Alert} alt="alertIcon" height={"60px"} />
          <h2>There was an error.</h2>

          <p>
            It seems that you do not have permission to access Employee Portal.
          </p>
          <p>Please logout or try different browser to login again.</p>

          <button className="logOut-btn" onClick={()=>logout()}>
            Log Out
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
