import { Descope } from "@descope/react-sdk";
import React from "react";
import { useNavigate } from "react-router-dom";
import { updateLastLogin } from "../../redux/features/providerSlice";
import { useAppDispatch } from "../../redux/store";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onLoginSuccess = async (email: any) => {
    await dispatch(updateLastLogin({ email: email }));
    navigate("/");
  };

  return (
    <Descope
      flowId="otp-sign-in-employee"
      onSuccess={(e) => {
        onLoginSuccess(e.detail.user?.email);
      }}
      onError={(e) => console.log("Could not log in!", e)}
    />
  );
};

export default Login;
